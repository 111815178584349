<template>
  <div
    v-show="loaderState.loading"
    class="loader-text">
    <FlexTextIcon
      icon="circle-notch"
      spin>
      <Translated msg="loader.loading"/>
    </FlexTextIcon>
  </div>
  <div
    v-show="loaderState.failed"
    class="loader-text text-danger">
    <FlexTextIcon icon="times-circle">
      <Translated msg="loader.failed"/>
    </FlexTextIcon>
  </div>
  <div
    v-show="showContent"
    class="loader-content">
    <slot name="default"/>
  </div>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import {computed, defineComponent, type PropType} from 'vue';
import FlexTextIcon from '../FlexTextIcon.vue';
import {type LoaderState} from './LoaderState';

export default defineComponent({
  components: {FlexTextIcon, Translated},
  props: {
    loaderState: {
      type: Object as PropType<LoaderState>,
      required: true,
    },
  },
  setup: (props) => {
    return {
      showContent: computed(() => !props.loaderState.loading && !props.loaderState.failed),
    };
  },
});
</script>
